import styled from '@emotion/styled'
import { Facebook, Telegram, Whatsapp } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from 'react-share'

const pageURL = typeof window !== 'undefined' ? window.location.href : '/'

export const Share = memo(function Share() {
  return (
    <Container row wrap>
      <FacebookShareButton url={pageURL}>
        <Facebook />
      </FacebookShareButton>

      <WhatsappShareButton url={pageURL}>
        <Whatsapp />
      </WhatsappShareButton>

      <TelegramShareButton url={pageURL}>
        <Telegram />
      </TelegramShareButton>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.25rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 0.0625rem solid ${rgba(theme.colors.variants.secondaryLight, 0.2)} !important;
    margin-right: 1rem;

    svg {
      width: auto;
      height: 1rem;
      fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }
`
