import styled from '@emotion/styled'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  category?: string
  date?: any
  languageCode: string
  subtitle?: string
  title: string
}

export const Intro = memo(function Intro({
  category,
  date,
  languageCode,
  subtitle,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      {category ? (
        <Category dial={5} row>
          <Rumble />
          {category}
        </Category>
      ) : null}

      <Title>{title}</Title>

      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

      {date ? (
        <Created>
          {new Date(date).toLocaleDateString(languageCode, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </Created>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2.5rem 1.875rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
`

const Category = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 0.9375rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-right: 0.625rem;
  }
`

const Title = styled.h1`
  max-width: 35.375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin: auto;
  text-transform: uppercase;
`

const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2.0625rem;
  margin-top: 0.375rem;
`

const Created = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.375rem;
  margin-top: 1.875rem;
  text-transform: capitalize;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
`
