import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 6rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2.0625rem;
  margin-top: 1.25rem;
`

const Background = styled.div`
  aspect-ratio: 1.78;
  margin-top: 3rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    aspect-ratio: 1.33;
    margin-top: 2.25rem;
  }
`
