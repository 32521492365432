import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import {
  Article,
  Props as ArticleProps,
} from 'app/components/Blog/List/Article'
import { Arrow } from 'app/components/Common/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

export interface Props {
  languageCode: string
  slides: ArticleProps[]
}

export const Carousel = memo(function Carousel({
  languageCode,
  slides,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 24,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1023px)': {
        loop: true,
        slides: {
          perView: 2.05,
          spacing: 8,
        },
      },
      '(max-width: 767px)': {
        loop: true,
        slides: {
          perView: 1,
          spacing: 8,
        },
      },
    },
  })

  return (
    <Container>
      <Title>{useVocabularyData('other-articles-title', languageCode)}</Title>

      <Slider ref={sliderRef}>
        {slides.map((item, index) => (
          <Article key={index} className="keen-slider__slide" {...item} />
        ))}
      </Slider>

      {slides.length > 2 ? (
        <Arrows row>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  padding: 6.25rem 11.944vw 7.625rem;
  position: relative;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  letter-spacing: 0.075rem;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
  }
`

const Slider = styled.div`
  display: flex;
  margin-top: 3.375rem;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    overflow: visible !important;
    margin-top: 2rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 4.8125rem;
  right: 11.944vw;

  @media (max-width: 1023px) {
    display: none;
  }
`
