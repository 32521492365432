import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Newsletter } from 'app/containers/Footer/Newsletter'
import { NewsletterFormSender } from 'app/utils/NewsletterFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Paragraph, Props as ParagraphProps } from './Paragraph'
import { Share } from './Share'

export interface Props {
  languageCode: string
  offersPageURL?: string
  paragraphs?: ParagraphProps[]
}

export const Content = memo(function Content({
  languageCode,
  offersPageURL,
  paragraphs,
}: Props) {
  const sendFormTo = useMemo(
    () =>
      NewsletterFormSender({
        formURL: 'https://a1e5x4.emailsp.com/frontend/subscribe.aspx',
      }),
    [],
  )

  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {paragraphs
          ? paragraphs.map((item, index) => <Paragraph key={index} {...item} />)
          : null}
      </LeftSide>

      <RightSide>
        <FadeInUp>
          <Label dial={4} row>
            {useVocabularyData('share', languageCode)}
          </Label>
        </FadeInUp>

        <Share />

        {offersPageURL ? (
          <>
            <FadeInUp>
              <Label className="spacing" dial={4} row>
                {useVocabularyData('offers-banner-title', languageCode)}
              </Label>
            </FadeInUp>

            <Banner to={offersPageURL}>
              <Rumble />

              <Description>
                {useVocabularyData('offers-banner-text', languageCode)}
              </Description>

              <CTA
                label={useVocabularyData('discover-offers', languageCode)}
                noActions
                variant="simple"
              />
            </Banner>
          </>
        ) : null}

        <FadeInUp>
          <Label className="spacing" dial={4} row>
            {useVocabularyData('newsletter', languageCode)}
          </Label>
        </FadeInUp>

        <Newsletter
          languageCode={languageCode}
          onNewsletterFormSendTo={async (data) => await sendFormTo(data)}
          variant="simple"
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding: 5.875rem 7.222vw 10.125rem;

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 75%;
  padding-right: 11.944vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`

const RightSide = styled.div`
  width: 25%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
  }
`

const Label = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 0.9375rem;
  text-transform: uppercase;
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    height: 0.0625rem;
    flex: 1;
    margin-left: 1.25rem;
  }
  &.spacing {
    margin-top: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.375rem;
`

const CTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 1.25rem;
  &:before,
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Banner = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 0.88;
  background: url('/banner-sidebar.jpg') no-repeat center;
  background-size: cover;
  margin-top: 1.25rem;
  padding: 0 3.3125rem;
  position: relative;
  text-align: center;
  &:hover {
    ${CTA} {
      &:before {
        right: 50%;
      }
      &:after {
        left: 50%;
      }
    }
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin: 0 auto 1.25rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    aspect-ratio: 1.5;
  }
`
