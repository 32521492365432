import styled from '@emotion/styled'
import { Carousel } from 'app/components/Blog/Carousel'
import { Content } from 'app/components/Blog/Content'
import { Intro } from 'app/components/Blog/Intro'
import { Hero } from 'app/components/Hero'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function BlogArticlePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          variant="offer"
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero variant="compact" {...context.heroProps} />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.contentProps ? <Content {...context.contentProps} /> : null}
      {context.carouselProps ? <Carousel {...context.carouselProps} /> : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          variant="simple"
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
